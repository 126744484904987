import React, { useState, useRef, useContext } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Button from "../Button";
import Input from "../Input";
import { addCow, submitEditCow } from "../../store/actions";
import { useOnClickOutside } from "../outsideClick";
import { RouterContext } from "../../containers/Contexts";

import { messages } from "./errorMessages";

import "./CowModal.css";

const CowModal = ({
    cow,
    addCow,
    submitEditCow,
    cowTypes,
    cowNumbers,
    cowIdentities,
    cowNames,
}) => {
    const { t } = useTranslation();
    const cowModalRef = useRef();
    const navigate = useContext(RouterContext);
    useOnClickOutside(cowModalRef, navigate.back);

    const [state, setState] = useState(
        cow
            ? cow
            : {
                farmNumber: null,
                name: null,
                identityNumber: null,
                birthdate: null,
                breed: cowTypes[0],
                lactationCount: null,
                lastCalvingDate: null,
                lastBreedingDate: null,
                isPregnant: null,
                place: null,
            },
    );
    const [validations, setValidations] = useState({
        farmNumber: null,
        name: null,
        identityNumber: null,
        birthdate: null,
        breed: null,
        lactationCount: null,
        lastCalvingDate: null,
        lastBreedingDate: null,
        isPregnant: null,
        place: null,
    });
    const [isPregnant, setIsPregnant] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    console.log(isPregnant);

    const stateRef = useRef();
    stateRef.current = state;

    const validationRef = useRef();
    validationRef.current = validations;

    function onInputChanged(key, value) {
        // ### DON'T CHANGE PREGNANT STATE FROM LAST BREEDING DATE ###
        // if (key === "lastBreedingDate") {
        //     if (value) {
        //         setIsPregnant(true);
        //     } else {
        //         setIsPregnant(false);
        //     }
        // }
        if (key === "identityNumber") value = value.toUpperCase();
        setState({ ...state, [key]: value });
        const err = validateInputs(key, value);
        const el = document.getElementById(key);
        if (err) {
            setValidations({ ...validationRef.current, [key]: err });
            el.classList.add("validate");
            setIsDisabled(true);
        } else {
            setValidations({ ...validationRef.current, [key]: null });
            el.classList.remove("validate");
            setIsDisabled(false);
        }
    }

    function onEditSubmitted() {
        const changes = Object.keys(stateRef.current)
            .filter((key) => {
                if (stateRef.current[key] !== cow[key]) return true;
                return false;
            })
            .reduce((acc, key) => {
                return { ...acc, [key]: stateRef.current[key] };
            }, {});
        const validations = Object.keys(changes).reduce((acc, key) => {
            const err = validateInputs(key, stateRef.current[key]);
            return { ...acc, [key]: err };
        }, {});
        if (
            Object.values(validations)
                .filter((v) => v && v.required)
                .every((v) => !v)
        ) {
            // submit
            submitEditCow(stateRef.current, isPregnant);
        } else {
            Object.keys(validations).map((v) => {
                const el = document.getElementById(v);
                if (validations[v]) {
                    el.classList.add("validate");
                }
                return v;
            });
            setValidations(validations);
        }
    }

    function onAddSubmitted() {
        const validations = Object.keys(stateRef.current).reduce((acc, key) => {
            const err = validateInputs(key, stateRef.current[key]);
            return { ...acc, [key]: err };
        }, {});
        if (
            Object.values(validations)
                .filter((v) => v && v.required)
                .every((v) => !v)
        ) {
            // submit
            addCow(stateRef.current, isPregnant);
        } else {
            Object.keys(validations).map((v) => {
                const el = document.getElementById(v);
                if (validations[v]) {
                    el.classList.add("validate");
                }
            });
            setValidations(validations);
        }
    }

    function validateInputs(key, value) {
        switch (key) {
            case "farmNumber":
                if (!value) return { text: messages[1], required: true };
                if (cowNumbers.includes(value))
                    return { text: messages[2], required: false };
                if (value.length > 5)
                    return { text: messages[3], required: true };
                return null;
            case "name":
                if (cowNames.includes(value))
                    return { text: messages[4], required: false };
                return null;
            case "identityNumber":
                if (!value) return { text: messages[5], required: true };
                if (cowIdentities.includes(value))
                    return { text: messages[6], required: true };
                return null;
            case "birthdate":
                if (!value) return { text: messages[7], required: true };
                if (!(value instanceof Date))
                    return { text: messages[8], required: true };
                return null;
            default:
                return null;
        }
    }

    return (
        <main className="cow-modal" ref={cowModalRef}>
            <div className="head">
                <h6>{cow ? t("editCow") : t("addACow")}</h6>
                <span className="close-button" onClick={navigate.back} />
            </div>
            <div id="cow-form">
                <Input
                    id="farmNumber"
                    classNames="farm-number"
                    label={t("farmNumber")}
                    onChanged={(value) => onInputChanged("farmNumber", value)}
                    inputType="number"
                    validationMessage={validations.farmNumber}
                    value={state.farmNumber}
                />
                <Input
                    id="name"
                    classNames="name"
                    label={t("name")}
                    onChanged={(value) => onInputChanged("name", value)}
                    validationMessage={validations.name}
                    value={state.name}
                />
                <Input
                    id="identityNumber"
                    classNames="identity"
                    label={t("identityNumber")}
                    onChanged={(value) =>
                        onInputChanged("identityNumber", value)
                    }
                    validationMessage={validations.identityNumber}
                    uppeerCase={true}
                    value={state.identityNumber}
                />
                <Input
                    id="birthdate"
                    type="date"
                    classNames="birth"
                    label={t("birthdate")}
                    onChanged={(value) => onInputChanged("birthdate", value)}
                    validationMessage={validations.birthdate}
                    disableFuture={true}
                    value={state.birthdate}
                />
                <Input
                    id="breed"
                    type="select"
                    classNames="breed"
                    label={t("breed")}
                    options={cowTypes}
                    onChanged={(value) => onInputChanged("breed", value)}
                    placeholder={t("select")}
                    validationMessage={validations.breed}
                    defaultValue={state.breed}
                    value={state.breed}
                />
                <label className="reproductive">{t("reproductiveState")}</label>
                <Input
                    id="lactationCount"
                    classNames="lactation"
                    label={t("lactation")}
                    onChanged={(value) =>
                        onInputChanged("lactationCount", value)
                    }
                    inputType="number"
                    validationMessage={validations.lactationCount}
                    value={state.lactationCount}
                />
                <Input
                    id="lastCalvingDate"
                    type="date"
                    classNames="calving top"
                    label={t("calvingDate")}
                    onChanged={(value) =>
                        onInputChanged("lastCalvingDate", value)
                    }
                    validationMessage={validations.lastCalvingDate}
                    disableFuture={true}
                    value={state.lastCalvingDate}
                />
                <Input
                    id="lastBreedingDate"
                    type="date"
                    classNames="insemination top"
                    label={t("inseminationDate")}
                    onChanged={(value) =>
                        onInputChanged("lastBreedingDate", value)
                    }
                    validationMessage={validations.lastBreedingDate}
                    disableFuture={true}
                    value={state.lastBreedingDate}
                />
                {/* {isPregnant ? (
                    <h6 className="info">
                        {t("assumingCowIsPregnantNow")}.
                        <a onClick={() => setIsPregnant(false)}>
                            {t("setToNotPregnant")}
                        </a>
                    </h6>
                ) : state.inseminationDate ? (
                    <h6 className="info">
                        {t("assumingCowIsNotPregnantNow")}
                        <a onClick={() => setIsPregnant(true)}>
                            {t("setToPregnant")}
                        </a>
                    </h6>
                ) : null} */}
                <Input
                    id="isPregnant"
                    type="checkbox"
                    classNames="pregnancy top"
                    label={t("isPregnant")}
                    onChanged={(e) => {
                        onInputChanged("isPregnant", !state.isPregnant);
                        setIsPregnant(!state.isPregnant);
                    }}
                    validationMessage={validations.isPregnant}
                    value={state.isPregnant}
                    checked
                />
            </div>
            <Button
                className={"submit"}
                text={cow ? t("editThisCow") : t("addThisCow")}
                type="action"
                behaviour="instant"
                onClicked={cow ? onEditSubmitted : onAddSubmitted}
                disabled={cow && isDisabled}
            />
        </main>
    );
};

function mapStateToProps({ cowModal, cowTypes, cows, oldItems }) {
    let cowsLocal = cows;
    if (oldItems.cowIds.length > 0) {
        cowsLocal = Object.values(cows).reduce((acc, c) => {
            if (oldItems.cowIds.includes(c.id)) return acc;
            return { ...acc, [c.id]: c };
        }, {});
    }
    return {
        ui: cowModal.ui,
        status: cowModal.status,
        cow: cowModal.cow,
        cowTypes: Object.values(cowTypes).reduce((acc, ct) => {
            return [...acc, { value: ct.id, label: ct.cowTypeName }];
        }, []),
        cowIdentities: Object.values(cowsLocal).map((c) => c.identityNumber),
        cowNumbers: Object.values(cowsLocal).map((c) => c.farmNumber),
        cowNames: Object.values(cowsLocal).map((c) => c.name),
    };
}

const mapDispatchToProps = { addCow, submitEditCow };

export default connect(mapStateToProps, mapDispatchToProps)(CowModal);
